<template>
    <template v-if="!isReservation && validations.warranties.length">
        <VeeField :name="'warranty'" rules="requiredCheckbox" v-model="vaildation.valid"
            v-slot="{ field, meta }" @change="updateFlag()">
            <div :class="classes + ' ' + (meta.touched ? (meta.valid) ? 'valid' : 'invalid' : '')">
                <label class="flex gap-1 items-baseline">
                    <div><input v-bind="field" :name="'warranty'" type="checkbox"
                        v-model="vaildation.valid" /></div>
                        <div><template v-for="entry in vaildation.warranties" :key="entry.warrantyId">
                            <CheckoutVeeFieldWarrantyEntry :item="entry.item" :warrantyId="entry.warrantyId">
                            </CheckoutVeeFieldWarrantyEntry>
                        </template></div>
                </label>
            </div>
        </VeeField>
    </template>
</template>
<script setup lang="ts">
const props = defineProps({
    isReservation: {
        type: Boolean,
        default: () => {
            return inject("isReservation");
        },
    },
    bins: {
        type: Object
    },
    classes: {
        type: String,
        default: "bg-secondary-light p-5 border after:hidden"
    }
})

let validations = {
    warranties: [],
    valid: useCartFormData().value.insuranceFlag
}
if (!props.isReservation) props.bins?.forEach(bin => {
    bin?.itemList?.items?.forEach(item => {
        item?.additionalItems?.items?.forEach(additionalItem => {
            if (additionalItem?.id?.indexOf('OLD_APPLIANCES_REMOVAL') < 0 && additionalItem?.articleId) {
                let warrantyEntry = {
                    "item" : item,
                    "warrantyId" : additionalItem.articleId
                };
                validations.warranties.push(warrantyEntry);
            }
        });
    });
});
const vaildation = ref(validations);

function updateFlag() {
    useCartFormData().value.insuranceFlag = validations.valid
    let formData = useCartFormData()?.value
    updateOrderFlags(useCurrentUserState()?.value?.csrfToken, formData.newsletterFlag, formData.termsFlag, formData.insuranceFlag)
}
</script>
