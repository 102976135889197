<template>
    <div class="mb-2" v-html="termsOfServiceDescription">
    </div>
</template>
<script setup>
    import { getExpArticle } from "~/composables/ExpApi/expArticle";
    const props = defineProps({
        item: {
            type: Object,
        },
        warrantyId: {
            type: String,
        }
    })
    const { data: usedArticle } = await getExpArticle(props.item?.articleId);
    const {data: warranties} = await useFetch('/api/warranty', {
        query: {
            webcode: usedArticle?.value.webcode,
            price: props.item?.price?.gross,
            productType: usedArticle?.value?.productType
        }
    })
    let warranty = ref()
    for(let i = 0; i < warranties?.value?.warrantyList?.length; i++) {
        if(warranties?.value?.warrantyList[i]?.typeName == props.warrantyId) {
            warranty.value = warranties?.value?.warrantyList[i]
            break
        }
    }
    const {data: type} = await useFetch('/api/warrantyType', {
        query: {
            typeName: warranty.value?.typeName
        }
    })

    const termsOfServiceDescription = ref(type?.value?.termsOfServiceDescription?.replace("[["+getBetween(type?.value?.termsOfServiceDescription, "[[", "]]")+"]]", 
    '<a href="'+warranty?.value?.listOfFiles?.list[0]?.fileUrl+'" class="text-primary" target="_blank" rel="noopener norefferer">'+
        getBetween(type?.value?.termsOfServiceDescription, "[[", "]]")+
    '</a>'))

    function getBetween(str, start, end) {
        return str.substring(str.indexOf(start)+end.length, str.indexOf(end))
    }
</script>
